<template>
    <div>
        <form class="card basic-card modal-card" @submit.prevent="handleSubmit">
            <div class="card-header row">
                <h3 class="card-title mt-2">{{ editedRouter.id ? `Editing Router: ${editedRouter.routerHost}` : 'Adding New Router' }}</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card-text-row">
                            <div class="highlight" ref="routerIdLabel">Router ID:</div>
                            <div class="input-group mb-3">
                                <span class="input-group-text" ref="routerIdIcon"><i class="fa-solid fa-fingerprint"></i></span>
                                <input class="form-control paws-text-input" type="text" v-model="editedRouter.routerId">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card-text-row">
                            <div class="highlight" ref="usernameLabel">Username:</div>
                            <div class="input-group mb-3">
                                <span class="input-group-text" ref="usernameIcon"><i class="fa-solid fa-envelope"></i></span>
                                <input class="form-control paws-text-input" type="text" v-model="editedRouter.username">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card-text-row">
                            <div class="highlight" ref="routerHostLabel">Router Host:</div>
                            <div class="input-group mb-3">
                                <span class="input-group-text" ref="routerHostIcon"><i class="fa-solid fa-file-signature"></i></span>
                                <input class="form-control paws-text-input" type="text" v-model="editedRouter.routerHost">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card-text-row">
                            <div class="highlight" ref="passwordLabel">Password:</div>
                            <div class="input-group mb-3">
                                <span class="input-group-text" ref="passwordIcon"><i class="fa-solid fa-lock"></i></span>
                                <input class="form-control paws-text-input" type="text" v-model="editedRouter.password">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-5">
                        <div class="card-text-row">
                            <div class="highlight">Encrypted:</div>
                            <div class="rowValue">
                                <label class="switch">
                                    <input type="checkbox" v-model="editedRouter.encrypted">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button ref="closeModalButton" type="button" class="d-none" data-bs-dismiss="modal" data-bs-target="#routerModal"></button>

            <button class="btn submit-button" type="submit">
                <span class="state">Save</span>
            </button>

        </form>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            working: false,
            editedRouter: {
                id: null,
                wifiNetworkId: null,
                routerId: '',
                routerHost: '',
                username: '',
                password: '',
                encrypted: false,
                created: ''
            }
        };
    },
    props: {
        router: {
            type: Object,
            required: true
        }
    },
    watch: {
        router: {
            immediate: true,
            handler(newValue) {
                this.editedRouter = { ...newValue };
            }
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            if (this.working) return;

            this.working = true;
            const $this = e.target,
            $state = $this.querySelector('button > .state');
            $this.classList.remove('ok', 'not-ok');

            this.validateFields().then(
                (success) => {
                    $this.classList.add('loading');
                    $state.innerHTML = 'Saving..';

                    this.saveRouter().then(
                        (response) => {
                            $this.classList.add('ok');
                            $state.innerHTML = 'Success';
                            setTimeout(() => {
                                this.$emit('sync');
                                this.closeModal();
                                setTimeout(() => {
                                    $this.classList.remove('ok', 'loading');
                                    $state.innerHTML = 'Save';
                                    this.working = false;
                                }, 200);
                            }, 1000);
                        },
                        (error) => {
                            $this.classList.add('not-ok');
                            $state.innerHTML = 'Error';
                            setTimeout(() => {
                                this.closeModal();
                                setTimeout(() => {
                                    $this.classList.remove('not-ok', 'loading');
                                    $state.innerHTML = 'Save';
                                    this.working = false;
                                }, 200);
                            }, 1000);
                        }
                    );
                },
                (error) => {
                    $state.innerHTML = 'Error: ' + error;
                    $this.classList.add('not-ok');
                    setTimeout(() => {
                        $state.innerHTML = 'Save';
                        $this.classList.remove('not-ok',);
                        this.working = false;
                    }, 2000);
                }
            )
            

            
        },
        saveRouter() {
            if (this.editedRouter.id) {
                return this.$http.put(this.$config.pawsAPI + '/v3/wifi/routers', this.editedRouter).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        console.error(error);
                        throw error;
                    }
                )
            } else {
                return this.$http.post(this.$config.pawsAPI + '/v3/wifi/routers', this.editedRouter).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        console.error(error);
                        throw error;
                    }
                )
            }
        },
        validateFields() {
            return new Promise((resolve, reject) => {
                
                let isValid = true;

                if (!this.editedRouter.routerId) {
                    this.$refs.routerIdLabel.classList.add('invalid-input-group-text');
                    this.$refs.routerIdIcon.classList.add('invalid-input-group-text');
                    isValid = false;
                } else {
                    this.$refs.routerIdLabel.classList.remove('invalid-input-group-text');
                    this.$refs.routerIdIcon.classList.remove('invalid-input-group-text');
                }

                if (!this.editedRouter.routerHost) {
                    this.$refs.routerHostLabel.classList.add('invalid-input-group-text');
                    this.$refs.routerHostIcon.classList.add('invalid-input-group-text');
                    isValid = false;
                } else {
                    this.$refs.routerHostLabel.classList.remove('invalid-input-group-text');
                    this.$refs.routerHostIcon.classList.remove('invalid-input-group-text');
                }

                if (!this.editedRouter.username) {
                    this.$refs.usernameLabel.classList.add('invalid-input-group-text');
                    this.$refs.usernameIcon.classList.add('invalid-input-group-text');
                    isValid = false;
                } else {
                    this.$refs.usernameLabel.classList.remove('invalid-input-group-text');
                    this.$refs.usernameIcon.classList.remove('invalid-input-group-text');
                }

                if (!this.editedRouter.password) {
                    this.$refs.passwordLabel.classList.add('invalid-input-group-text');
                    this.$refs.passwordIcon.classList.add('invalid-input-group-text');
                    isValid = false;
                } else {
                    this.$refs.passwordLabel.classList.remove('invalid-input-group-text');
                    this.$refs.passwordIcon.classList.remove('invalid-input-group-text');
                }

                if (isValid) {
                    resolve('All fields are valid');
                } else {
                    reject('Missing Fields');
                }

            });
        },
        deleteRouter() {
            this.$emit('delete');
        },
        closeModal() {
            this.$refs.closeModalButton.click();
        }
    }
};
</script>
  
<style scoped>
    .modal {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    
    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }
    
    .close:hover,
    .close:focus {
        color: var(--highlighted-text);
        text-decoration: none;
        cursor: pointer;
        transition: all 0.6s ease-in-out; 
    }
    
    .modal-buttons {
        margin-top: 20px;
    }
    
    .modal-buttons button {
        margin-right: 10px;
    }
  
    .modal-card {
        border-width: 3px;
        border-radius: 0px;
        border-color: var(--highlighted-text);
        
        color: var(--body-text-color);
        background-color: var(--body-card-color);
        box-shadow: 5px 5px 2px #00000075; /* Define custom shadow */

        transition: all 0.6s ease-in-out; 
    }

    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-text-row {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 15px; /* Adjust as needed for spacing between rows */
    }

    .static-text {
        color: var(--input-placeholder-color);
    }

    .highlight {
        transition: all .6s ease-in-out;
        margin-bottom: 5px; /* Adjust as needed for spacing between label and input */
        text-align: center;
    }

    .paws-text-input {
        border-width: 4px 4px 4px 0px;
        border-radius: 0px 0.375rem 0.375rem 0px;
        color: var(--input-placeholder-color);
        border-color: var(--input-border-color);
        background-color: var(--input-background-color);

        transition: all .6s ease-in-out;
    }

    .paws-text-input:hover,
    .paws-text-input:focus {
        color: var(--highlighted-text);
        border-color: var(--input-border-color);
        background-color: var(--input-background-color);
    }

    .input-group-text {
        border-width: 4px 0px 4px 4px;
        color: var(--text-header-color);
        background: var(--input-border-color);
        border-color: var(--input-border-color);
        transition: all .6s ease-in-out;
    }

    .invalid-input-group-text {
        color: var(--error-color) !important;
    }

    .submit-button, .submit-button:focus {
        left:0;
        bottom:0;
        width: 100%;
        height: 3em;
        z-index: 10;
        border-radius: 0;
        color: #FFFFFF;
        position: absolute;
        
        border-color: var(--button-color);
        background-color: var(--button-color);
        
        transition: all 0.6s ease-in-out;
    }

    .submit-button:hover {
        color: #FFFFFF;
        border-color: var(--highlighted-text);
        background-color: var(--highlighted-text);
    }

    .modal-card.loading .submit-button {
        width: 100%;
        height: 100%;
        background-color: var(--button-color);
        transition: all 0.7s ease-in-out; 
    }

    .modal-card.ok {
        border-color: var(--highlighted-text);
    }

    .modal-card.not-ok {
        border-color: var(--error-color);
    }

    .modal-card.ok .submit-button  {
        border-color: var(--highlighted-text);
        background-color: var(--highlighted-text);
    }

    .modal-card.not-ok .submit-button  {
        border-color: var(--error-color);
        background-color: var(--error-color);
    }

     /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 48px;
        height: 24px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #424242;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 0px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    } 

</style>