<template>
    <div>
  
        <div class="card basic-card">
            <!-- <button class="btnAdd" data-bs-toggle="modal" data-bs-target="#orgModal"><i class="fa-solid fa-gear"></i></button> -->
            <div class="card-body">
                <h1 class="card-title highlight">{{ auth.getOrgName() }}</h1>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-md-2">
            <div class="col mt-4">
                <div class="card basic-card">
                    <div class="card-body">
                        <button class="btnAdd" data-bs-toggle="modal" data-bs-target="#orgUserModal" @click="newOrgUser()"><i class="addIco fa-regular fa-square-plus"></i></button>
                        <h3 class="card-title highlight">Organisation Users</h3>
                        <hr/>
                        <div class="card-list mt-4">
                            <div class="table-responsive table-bordered">
                                <table class="paws-table table">
                                    <thead>
                                        <tr>
                                            <th>Username</th>
                                            <th class="btn-th"></th>
                                            <th class="btn-th"></th>
                                            <th class="btn-th"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(user, index) in orgUsers" :key="index + '-' + user.id">
                                            <td>{{ user.username }}</td>
                                            <td>
                                                <button 
                                                    class="btn bordoff highgreen"
                                                    data-bs-toggle="modal" 
                                                    data-bs-target="#orgUserModal"
                                                    v-tooltip="'Edit User'"
                                                    @click="editOrgUser(user)"
                                                >
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button 
                                                    class="btn bordoff highred"
                                                    v-tooltip="'Delete User'"
                                                    @click="deleteOrgUser(user)"
                                                >
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col mt-4">
                <div class="card basic-card">
                    <div class="card-body">
                        <h3 class="card-title highlight">Authorization Token</h3>
                        <hr/>
                        <div class="realm-list mt-4">
                            JSI-3J5-DH4-DH3-LLT-AL4
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <!-- Org Modal -->
    <div class="modal fade" id="orgModal" tabindex="-1" aria-labelledby="orgModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <OrgModal
                    :org="org"
                    @sync="syncData()"
                />
            </div>
        </div>
    </div>

    <!-- Org User Modal -->
    <div class="modal fade" id="orgUserModal" tabindex="-1" aria-labelledby="orgUserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <OrgUserModal
                    :orgId="auth.getOrg()"
                    :user="selectedUser"
                    @sync="syncData()"
                />
            </div>
        </div>
    </div>

    </div>  
</template>

<script>
import { authStore } from '@/stores/AuthStore.ts';
import OrgUserModal from '@/components/modal/OrgUserModal.vue';
import OrgModal from '@/components/modal/OrgModal.vue';

export default {
    name: 'Dashboard',
    components: {
        OrgModal,
        OrgUserModal,
    },
    props: {
        orgId: {
            type: String,
            required: true
        },
    },
    setup() {
    
    },
    data() {
        const auth = authStore()

        return {
            auth,

            org: {},
            orgUsers: [],
            selectedUser: {
                username: '',
                password: '',
                organizationId: this.orgId
            },
        }
    },
    mounted() {
        this.syncData();
                
    },
    methods: {
        syncData: function() {
            this.getOrgUsers();
        },
        getOrgUsers: function() {
            this.$http.get(this.$config.pawsAPI + '/v3/wifi/organizations/users/' + this.auth.getOrg()).then(
            (response) => {
                this.orgUsers = response.data;
            },
            (error) => {
                console.error(error);
            }
            )
        },
        newOrgUser() {
            this.selectedUser = {
                username: '',
                password: '',
                organizationId: this.orgId}
        },
        editOrgUser(user) {
            this.selectedUser = user;
        },
        deleteOrg() {
            const swal = require('sweetalert2');
            swal.fire({
                title: "Delete Organisation",
                text: "Are you sure?",
                icon: "warning",
                color: "#E0E0E0",
                iconColor: "#D71A20",
                background: "#212529",
                confirmButtonColor: "#D71A20",
                showCancelButton: true,
                confirmButtonText: "Delete",
                cancelButtonText: `Cancel`
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$http.delete(this.$config.pawsAPI + '/v3/wifi/organizations/' + this.orgId).then(
                    (response) => {
                        swal.fire({
                            title: "Success",
                            text: "Successfully Deleted Organisation.",
                            icon: "success",
                            color: "#E0E0E0",
                            iconColor: "##3dbe59",
                            background: "#212529",
                            confirmButtonColor: "#0D6EFD",
                        }).then((a) => {
                            if (this.auth.getAdmin() == "true") {
                                this.$router.push('orgs');   
                            } else {
                                this.$router.push('login');   
                            }
                        });
                    },
                    (error) => {
                        console.error(error);
                    }
                    )
                }
            });
        },
        deleteOrgUser(user) {
            const swal = require('sweetalert2');
            swal.fire({
                title: "Delete Org User: " + user.username,
                text: "Are you sure?",
                icon: "warning",
                color: "#E0E0E0",
                iconColor: "#D71A20",
                background: "#212529",
                confirmButtonColor: "#D71A20",
                showCancelButton: true,
                confirmButtonText: "Delete",
                cancelButtonText: `Cancel`
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$http.delete(this.$config.pawsAPI + '/v3/wifi/organizations/users/' + user.id).then(
                    (response) => {
                        this.syncData();  
                        swal.fire({
                            title: "Success",
                            text: "Successfully Deleted Org User.",
                            icon: "success",
                            color: "#E0E0E0",
                            iconColor: "##3dbe59",
                            background: "#212529",
                            confirmButtonColor: "#0D6EFD",
                        })
                    },
                    (error) => {
                        console.error(error);
                    }
                    )
                }
            });
        },
    },
}
</script>
