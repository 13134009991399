import { defineStore } from 'pinia';

export const authStore = defineStore("authStore", {
  state: () => (
        {
            token: localStorage.getItem('token') || '',
            admin: localStorage.getItem('admin') || 'false',
            organizationName: localStorage.getItem('organizationName') || '',
            organizationId: localStorage.getItem('organizationId') || 0
        }
    ),
  actions: {
        setToken(token) {
            this.token = token;
            localStorage.setItem('token', token);
        },
        setOrgName(organizationName) {
            this.organizationName = organizationName;
            localStorage.setItem('organizationName', organizationName);
        },
        setOrg(organizationId) {
            this.organizationId = organizationId;
            localStorage.setItem('organizationId', organizationId);
        },
        setAdmin(admin) {
            this.admin = admin;
            localStorage.setItem('admin', admin + "");
        },
        getToken() {
            return this.token;
        },
        getOrgName() {
            return this.organizationName;
        },
        getOrg() {
            return parseInt(this.organizationId);
        },
        getAdmin() {
            return this.admin + "";
        }
    },
});
