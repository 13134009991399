 var pawsAPI = 'https://hotspot.servicepanel.co.za/api';
//var pawsAPI = 'http://localhost:8080/api';

if (window.origin.indexOf('PAWS') != -1) {
    pawsAPI = 'https://hotspot.servicepanel.co.za/api';
} else {
    console.warn('Could not determine env, defaulting to local');
}

export default {
    pawsAPI
}
