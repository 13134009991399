<template>
    <div>
        <form class="card basic-card modal-card" @submit.prevent="handleSubmit">
            <div class="card-header row">
                <h3 class="card-title mt-2">{{ newNetwork.id ? `Editing Network` : 'Adding New Network' }}</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div class="card-text-row">
                            <div class="highlight" ref="networkSsidLabel">SSID:</div>
                            <div class="input-group mb-3">
                                <span class="input-group-text" ref="networkSsidIcon"><i class="fa-solid fa-fingerprint"></i></span>
                                <input class="form-control paws-text-input" type="text" v-model="newNetwork.ssid">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col">
                        <div class="card-text-row">
                            <div class="highlight" ref="networkDomainLabel">Domain:</div>
                            <div class="input-group mb-3">
                                <span class="input-group-text" ref="networkDomainIcon"><i class="fa-solid fa-at"></i></span>
                                <input class="form-control paws-text-input" type="text" v-model="newNetwork.domain">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button ref="closeModalButton" type="button" class="d-none" data-bs-dismiss="modal" data-bs-target="#networkModal"></button>

            <button class="btn submit-button" type="submit">
                <span class="state">Create</span>
            </button>

        </form>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            working: false,
            newNetwork: {
                organizationId: this.orgId,
                id: '',
                ssid: '',
                domain: ''
            }
        };
    },
    props: {
        orgId: {
            type: Number,
            required: true
        },
        network: {
            type: Object,
            required: false
        },
    },
    watch: {
        network: {
            immediate: true,
            handler(newValue) {
                this.newNetwork = { ...newValue };
            }
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            if (this.working) return;

            this.working = true;
            const $this = e.target,
            $state = $this.querySelector('button > .state');
            $this.classList.remove('ok', 'not-ok');

            this.validateFields().then(
                (success) => {
                    $this.classList.add('loading');
                    $state.innerHTML = 'Saving..';

                    this.saveNetwork().then(
                        (response) => {
                            $this.classList.add('ok');
                            $state.innerHTML = 'Success';
                            setTimeout(() => {
                                this.$emit('sync');
                                this.closeModal();
                                setTimeout(() => {
                                    $this.classList.remove('ok', 'loading');
                                    $state.innerHTML = 'Create';
                                    this.working = false;
                                }, 200);
                            }, 1000);
                        },
                        (error) => {
                            $this.classList.add('not-ok');
                            $state.innerHTML = 'Error';
                            setTimeout(() => {
                                this.closeModal();
                                setTimeout(() => {
                                    $this.classList.remove('not-ok', 'loading');
                                    $state.innerHTML = 'Create';
                                    this.working = false;
                                }, 200);
                            }, 1000);
                        }
                    );
                },
                (error) => {
                    $state.innerHTML = 'Error: ' + error;
                    $this.classList.add('not-ok');
                    setTimeout(() => {
                        $state.innerHTML = 'Create';
                        $this.classList.remove('not-ok',);
                        this.working = false;
                    }, 2000);
                }
            )
            

            
        },
        saveNetwork() {
            if (this.newNetwork.id) {
                return this.$http.put(this.$config.pawsAPI + '/v3/wifi/networks', this.newNetwork).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        console.error(error);
                        throw error;
                    }
                )
            } else {
                return this.$http.post(this.$config.pawsAPI + '/v3/wifi/networks', this.newNetwork).then(
                    (response) => {
                        this.newNetwork = {
                            organizationId: this.orgId,
                            ssid: '',
                            domain: ''
                        }
                        return response.data;
                    },
                    (error) => {
                        console.error(error);
                        throw error;
                    }
                )
            }
        },
        validateFields() {
            return new Promise((resolve, reject) => {
                
                let isValid = true;

                if (!this.newNetwork.ssid) {
                    this.$refs.networkSsidLabel.classList.add('invalid-input-group-text');
                    this.$refs.networkSsidIcon.classList.add('invalid-input-group-text');
                    isValid = false;
                } else {
                    this.$refs.networkSsidLabel.classList.remove('invalid-input-group-text');
                    this.$refs.networkSsidIcon.classList.remove('invalid-input-group-text');
                }

                if (!this.newNetwork.domain) {
                    this.$refs.networkDomainLabel.classList.add('invalid-input-group-text');
                    this.$refs.networkDomainIcon.classList.add('invalid-input-group-text');
                    isValid = false;
                } else {
                    this.$refs.networkDomainLabel.classList.remove('invalid-input-group-text');
                    this.$refs.networkDomainIcon.classList.remove('invalid-input-group-text');
                }

                if (isValid) {
                    resolve('All fields are valid');
                } else {
                    reject('Missing Fields');
                }

            });
        },
        deleteNetwork() {
            this.$emit('delete');
        },
        closeModal() {
            this.$refs.closeModalButton.click();
        }
    }
};
</script>
  
<style scoped>
    .modal {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    
    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }
    
    .close:hover,
    .close:focus {
        color: var(--highlighted-text);
        text-decoration: none;
        cursor: pointer;
        transition: all 0.6s ease-in-out; 
    }
    
    .modal-buttons {
        margin-top: 20px;
    }
    
    .modal-buttons button {
        margin-right: 10px;
    }
  
    .modal-card {
        border-width: 3px;
        border-radius: 0px;
        border-color: var(--highlighted-text);
        
        color: var(--body-text-color);
        background-color: var(--body-card-color);
        box-shadow: 5px 5px 2px #00000075; /* Define custom shadow */

        transition: all 0.6s ease-in-out; 
    }

    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-text-row {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 15px; /* Adjust as needed for spacing between rows */
    }

    .static-text {
        color: var(--input-placeholder-color);
    }

    .highlight {
        transition: all .6s ease-in-out;
        margin-bottom: 5px; /* Adjust as needed for spacing between label and input */
        text-align: center;
    }

    .paws-text-input {
        border-width: 4px 4px 4px 0px;
        border-radius: 0px 0.375rem 0.375rem 0px;
        color: var(--input-placeholder-color);
        border-color: var(--input-border-color);
        background-color: var(--input-background-color);

        transition: all .6s ease-in-out;
    }

    .paws-text-input:hover,
    .paws-text-input:focus {
        color: var(--highlighted-text);
        border-color: var(--input-border-color);
        background-color: var(--input-background-color);
    }

    .input-group-text {
        border-width: 4px 0px 4px 4px;
        color: var(--text-header-color);
        background: var(--input-border-color);
        border-color: var(--input-border-color);
        transition: all .6s ease-in-out;
    }

    .invalid-input-group-text {
        color: var(--error-color) !important;
    }

    .submit-button, .submit-button:focus {
        left:0;
        bottom:0;
        width: 100%;
        height: 3em;
        z-index: 10;
        border-radius: 0;
        color: #FFFFFF;
        position: absolute;
        
        border-color: var(--button-color);
        background-color: var(--button-color);
        
        transition: all 0.6s ease-in-out;
    }

    .submit-button:hover {
        color: #FFFFFF;
        border-color: var(--highlighted-text);
        background-color: var(--highlighted-text);
    }

    .modal-card.loading .submit-button {
        width: 100%;
        height: 100%;
        background-color: var(--button-color);
        transition: all 0.7s ease-in-out; 
    }

    .modal-card.ok {
        border-color: var(--highlighted-text);
    }

    .modal-card.not-ok {
        border-color: var(--error-color);
    }

    .modal-card.ok .submit-button  {
        border-color: var(--highlighted-text);
        background-color: var(--highlighted-text);
    }

    .modal-card.not-ok .submit-button  {
        border-color: var(--error-color);
        background-color: var(--error-color);
    }
</style>