<template>
  <ul class="nav nav-tabs nav-fill responsive-tabs" role="tablist">
    <li class="nav-item" v-for="part in parts" :key="part.name" @click="activeTab = part.name">
      <a class="nav-link" :class="{'active' : activeTab === part.name}" :id="part.name + '-tab'" data-toggle="tab"
        :href="'#'+part.name" role="tab" :aria-controls="part.name" aria-selected="true">
        <h4 class="tab-header"><i :class="part.icon"></i> {{part.displayName}}</h4>
      </a>
    </li>
  </ul>
  <div class="card basic-card tab-card">
    <div class="card-body">
      <div v-if="currentPart" :key="currentPart.name" class="tab-pane fade show" 
      :id="currentPart.name" role="tabpanel" :aria-labelledby="currentPart.name + '-tab'">
        <slot :name="currentPart.name">
          {{currentPart.name}} needs to be implemented
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    parts: {
      required: true,
      type: Array
    },
    setActiveTab: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      activeTab: ''
    }
  },
  computed: {
    currentPart() {
      return this.parts.find(part => part.name === this.activeTab);
    }
  },
  mounted() {
    this.activeTab = this.setActiveTab || this.parts[0].name;
  },
  watch: {
    setActiveTab: {
      immediate: true,
      handler(val) {
        this.activeTab = val;
      }
    }
  }
}
</script>

<style>
.tab-fade-enter-active,
.tab-fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.tab-fade-enter,
.tab-fade-leave-to /* .tab-fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.basic-card.tab-card {
  border: none;
  box-shadow: none; /* Define custom shadow */
}

.tab-header {
  padding: 0.5em;
}

/* Active tab style */
.nav-tabs .nav-item .nav-link.active {
  background-color: var(--body-card-color);
  color: var(--header-highlight);
}

/* Inactive tab style */
.nav-tabs .nav-item .nav-link {
  background-color: var(--body-card-dark);
  color: var(--text-header-color);
  border-radius: 20px 20px 0 0;
  border: none;
  transition: all 0.2s ease-in-out;
}

.nav-tabs .nav-item .nav-link:hover {
  color: var(--header-highlight);
}

/* Optional: custom tab item styles */
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
</style>

