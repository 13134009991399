<template>
    <div class="background">
        <vue-particles
            color="#FFFFFF"
            :particleOpacity="0.7"
            :particlesNumber="numOfParts"
            shapeType="circle"
            :particleSize="2"
            linesColor="#ffffff"
            :lineLinked="true"
            :lineOpacity="0.4"
            :lineWidth="1"
            :moveSpeed="1"
            :hoverEffect="false"
            :clickEffect="false"
        />
    </div>
</template>
  
<script>
export default {
    setup() {
        return {
            
        }
    },
    props: {
        numOfParts: {
            type: Number,
            required: true
        },
        onHover: {
            type: Boolean,
            default: true
        },
    },
}
</script>

<style scoped>
.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>