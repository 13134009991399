import { createRouter, createWebHistory } from 'vue-router'

import Login from '../pages/Login.vue'

import Organisations from '../pages/Organisations.vue'
import OrgDetails from '@/pages/OrgDetails.vue'
import Dashboard from '../pages/Dashboard.vue'
import Network from '../pages/Network.vue'
import User from '../pages/User.vue'
import Device from '../pages/Device.vue'
import GsmNetwork from '../pages/GsmNetwork.vue'
import GsmAccount from '../pages/GsmAccount.vue'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            redirect: '/login'
          },
        {
            path: '/login',
            component: Login
        },
        {
            path: '/orgs',
            component: Organisations
        },
        {
            path: '/org/:orgId',
            component: OrgDetails,
            props: true
        },
        {
            path: '/dashboard',
            component: Dashboard
        },
        {
            path: '/network/:networkId/user/:msisdn/device/:macAddress',
            component: Device,
            props: true
        },
        {
            path: '/network/:networkId/user/:msisdn',
            component: User,
            props: true
        },
        {
            path: '/network/:networkId',
            component: Network,
            props: true
        },
        {
            path: '/gsmnetwork',
            component: GsmNetwork
        },
        {
            path: '/gsmaccount',
            component: GsmAccount
        },
    ],
})

export default router