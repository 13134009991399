<template>
    <div class="card mb-3 user-card curse-point" @click="toUser()">
        <div class="card-header"><h5>{{ msisdn }}</h5></div>
        <div class="card-body">
            <div class="card-text">  
                <div class="highlight">Currently Capped:</div>
                <div class="rowValue">{{ capped ? "Yes" : "No" }}</div>
            </div>
            <div class="card-text">
                <div class="highlight">Created:</div>
                <div class="rowValue">{{ created.split(" ")[0] }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        return {
            
        }
    },
    props: {
        id: {
            type: Number,
            required: true
        },
        networkId: {
            type: Number,
            required: true
        },
        msisdn: {
            type: String,
            required: true
        },
        capped: {
            type: Boolean,
            required: true
        },
        created: {
            type: String,
            required: true
        }
    },
    methods: {
        toUser() {
            this.$router.push('/network/' + this.networkId + '/user/' + this.msisdn);   
        },
    }
}
</script>

<style scoped>
.user-card {
    border-width: 3px;
    border-radius: 0px;
    border-color: var(--input-border-color);
    
    color: var(--body-text-color);
    background-color: var(--body-card-color);
    box-shadow: 5px 5px 2px #00000075; /* Define custom shadow */

    transition: all 0.6s ease-in-out; 
}

.user-card:hover {
    border-color: var(--highlighted-text);
}

.card-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rowValue {
    margin-left: 0.5em;
}

</style>