<template>
  <div>
    <div class="card basic-card">
      <button class="btnAdd" data-bs-toggle="modal" data-bs-target="#networkModal"><i class="fa-solid fa-gear"></i></button>
      <div class="card-body" v-if="network">
        <h1 class="card-title highlight">Network</h1>
        <p class="card-text">
          <span class="highlight">SSID: </span> {{ network.ssid }}
        </p>
        <p class="card-text">
          <span class="highlight">Domain: </span> {{ network.domain }}
        </p>
        <p class="card-text">
          <span class="highlight">Created: </span> {{ network.created.split(" ")[0] }}
        </p>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2">
      <div class="col-md-4 mt-4">
        <div class="card basic-card">
          <div class="card-body">
            <button class="btnAdd" data-bs-toggle="modal" data-bs-target="#routerModal" @click="newRouter"><i class="addIco fa-regular fa-square-plus"></i></button>
            <!-- <button class="btnDel" @click="deleteRoutersOn"><i class="fa-solid fa-trash"></i></button> -->
            <h3 class="card-title highlight">Routers</h3>
            <hr/>
            <div class="card-list mt-4">
              <RouterCard v-for="(router, index) in routers" :key="index + router.id"
                :id="router.id"
                :networkId="router.wifiNetworkId"
                :routerId="router.routerId"
                :routerHost="router.routerHost"
                :username="router.username"
                :password="router.password"
                :encrypted="router.encrypted"
                :created="router.created"

                @click="selectRouter(router)"
                data-bs-toggle="modal" 
                data-bs-target="#routerModal"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 mt-4">
        <div class="card basic-card">
          <div class="card-body">
            <button class="btnAdd" data-bs-toggle="modal" data-bs-target="#userModal" @click="newUser"><i class="addIco fa-regular fa-square-plus"></i></button>
            <h3 class="card-title highlight">Users</h3>
            <hr/>
            <div class="card-list mt-4">
              <div class="table-responsive table-bordered">
                <table class="paws-table table">
                  <thead>
                    <tr>
                      <th>MSISDN</th>
                      <th>Capped</th>
                      <th>Created</th>
                      <!-- <th class="btn-th"></th>
                      <th class="btn-th"></th>
                      <th class="btn-th"></th> -->
                    </tr>
                  </thead>
                  <tbody class="interactable-table">
                    <tr
                      v-for="(user, index) in users"
                      :key="index + '-' + user.id"
                      @click="goToUser(user.msisdn)"
                    >
                      <td>{{ user.msisdn }}</td>
                      <td :class="user.currentlyCapped ? 'highred' : 'highgreen'" @click="selectUser(user)">
                        <b>
                          {{ user.currentlyCapped ? 'Yes' : 'No' }}
                        </b>
                      </td>
                      <td>{{ user.created.split(" ")[0] }}</td>
                      <!-- <td>
                        <button 
                            class="btn bordoff highlight"
                            data-bs-toggle="modal" 
                            data-bs-target="#userModal"
                            v-tooltip="'Edit User'"
                            @click="selectUser(user)"
                        >
                            <i class="fa fa-edit"></i>
                        </button>
                      </td>
                      <td>
                        <button 
                            class="btn bordoff highred"
                            v-tooltip="'Delete User'"
                            @click="deleteUser(user)"
                        >
                            <i class="fa fa-trash"></i>
                        </button>
                      </td>
                      <td></td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Router Modal -->
    <div class="modal fade" id="routerModal" tabindex="-1" aria-labelledby="routerModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <RouterModal
              :router="selectedRouter"
              @sync="syncData()"
            />
        </div>
      </div>
    </div>

    <!-- User Modal -->
    <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="routerModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <UserModal
              :user="selectedUser"
              @sync="syncData()"
            />
        </div>
      </div>
    </div>

    <!-- Network Modal -->
    <div class="modal fade" id="networkModal" tabindex="-1" aria-labelledby="networkModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <NetworkModal
            :orgId="auth.getOrg()"
            :network="network"
            @sync="syncData()"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { authStore } from '@/stores/AuthStore.ts';
import UserCard from '@/components/objects/UserCard'
import RouterCard from '@/components/objects/RouterCard'
import UserModal from '@/components/modal/UserModal'
import RouterModal from '@/components/modal/RouterModal.vue'
import NetworkModal from '@/components/modal/NetworkModal.vue';

export default {
    name: 'Network',
    components: {
      // UserCard,
      RouterCard,
      UserModal,
      RouterModal,
      NetworkModal,
    },
    props: {
        networkId: {
            type: String,
            required: true
        },
    },
    data() {
        const auth = authStore()

        return {
          auth,
          network: {
            ssid: '',
            domain: '',
            created: ''
          },
          routers: 0,
          users: '',
          deleteRouters: false,

          selectedRouter: {},
          selectedUser: {},

        }
    },
    mounted() {
        this.syncData();
    },
    methods: {
      syncData() {
        this.getNetwork();
        this.getRouters();
        this.getUsers();
      },
      getNetwork: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/networks/organization/' + this.auth.getOrg() + '/network/' + this.networkId).then(
          (response) => {
            this.network = response.data;
          },
          (error) => {
            console.error(error);
          }
        )
      },
      getUsers: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/users/network/' + this.networkId).then(
          (response) => {
            this.users = response.data;
          },
          (error) => {
            console.error(error);
          }
        )
      },
      getRouters: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/routers/network/' + this.networkId).then(
          (response) => {
            this.routers = response.data
          },
          (error) => {
            console.error(error);
          }
        )
      },
      newRouter() {
        this.selectedRouter = {
          id: 0,
          wifiNetworkId: this.networkId,
          routerId: '',
          routerHost: '',
          username: '',
          password: '',
          encrypted: false
        }
      },
      newUser() {
        this.selectedUser = {
          id: 0,
          wifiNetworkId: this.networkId,
          msisdn: '',
          currentlyCapped: false
        }
      },
      openRouterModal(router) {
        this.$refs.routerModal.openModal(router);
      },
      selectRouter(router) {
        this.selectedRouter = router;
      },
      selectUser(user) {
        this.selectedUser = user;
      },
      goToUser(num) {
        this.$router.push('/network/' + this.networkId + '/user/' + num);   
      },
      deleteUser() {

      },
      deleteRoutersOn() {
        this.deleteRouters = true;
      },
      deleteRoutersOff() {
        this.deleteRouters = false;
      },
    },
}
</script>
