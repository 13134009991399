<template>
  <div>
    <div class="card basic-card">
      <button class="btnAdd" data-bs-toggle="modal" data-bs-target="#deviceModal"><i class="fa-solid fa-gear"></i></button>
      <button class="btnDel" @click="deleteDevice"><i class="fa-solid fa-trash"></i></button>
            <div class="card-body">
        <h1 class="card-title highlight mb-4">{{ device.description }}</h1>
        <div class="row">
          <div class="col-md-3"/>
          <p class="card-text col-md-3">
            <span class="highlight">MAC Address:</span> {{ device.macAddress }}
          </p>
          <p class="card-text col-md-3">
            <span class="highlight">Status: </span> {{ device.status }} 
          </p>
        </div>
        <div class="row">
          <div class="col-md-3"/>
          <p class="card-text col-md-3">
            <span class="highlight">MSISDN: </span> {{ user.msisdn }} 
          </p>
          <p class="card-text col-md-3">
            <span class="highlight">Currently Capped:</span> <span :class="user.currentlyCapped ? 'highred' : 'highgreen'"> {{ user.currentlyCapped ? 'Yes' : 'No' }} </span>
          </p>
        </div>
        <div class="row">
          <div class="col-md-3"/>
          <p class="card-text col-md-3">
            <span class="highlight">SSID:</span> {{ network.ssid }}
          </p>
          <p class="card-text col-md-3">
            <span class="highlight">Domain:</span> {{ network.domain }}
          </p>
        </div>
        <p class="card-text">
          <span class="highlight">Created: </span> {{ device.created.split(' ')[0] }} 
        </p>
      </div>
    </div>

    <div class="row row-cols-1">
      <div class="col mt-4">

          <CardTabs :parts="parts">
            <template v-slot:sessions>
              <div class="card-list mt-4">
                <div class="table-responsive table-bordered">
                  <table class="paws-table table" v-if="refreshBool">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Station</th>
                        <th>Duration</th>
                        <th>IP Address</th>
                        <th>Upload</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(s, index) in sessions"
                        :key="index + '-DataAllocation'"
                      >
                        <td>{{ s.username }}</td>
                        <td>{{ s.calledStationId}}</td>
                        <td>{{ s.duration }}</td>
                        <td>{{ s.ipAddressV4 }}</td>
                        <td>{{ $sizeBytesToMb(s.outBytes) }} <span class="highlight">MB</span></td>
                        <td>{{ $sizeBytesToMb(s.inBytes) }} <span class="highlight">MB</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
            <template v-slot:summary>
              <div class="card-list mt-4">
                <div class="table-responsive table-bordered">
                  <table class="paws-table table" v-if="refreshBool">
                    <thead>
                      <tr>
                        <th>Created</th>
                        <th>Upload</th>
                        <th>Download</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(su, index) in summary"
                        :key="index + '-DataAllocation'"
                      >
                        <td>{{ su.created.split(" ")[0] }}</td>
                        <td>{{ $sizeBytesToMb(su.outBytes) }} <span class="highlight">MB</span></td>
                        <td>{{ $sizeBytesToMb(su.inBytes) }} <span class="highlight">MB</span></td>
                        <td>{{ $sizeBytesToMb(su.outBytes + su.inBytes) }} <span class="highlight">MB</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </CardTabs>

      </div>
    </div>

    <!-- Device Modal -->
    <div class="modal fade" id="deviceModal" tabindex="-1" aria-labelledby="deviceModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <DeviceModal
            :msisdn="msisdn"
            :device="device"
            @sync="syncData()"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { authStore } from '@/stores/AuthStore.ts';
import CardTabs from '@/components/objects/CardTabs.vue';
import DeviceModal from '@/components/modal/DeviceModal.vue';

export default {
    name: 'Device',
    components: {
      CardTabs,
      DeviceModal,
    },
    props: {
        networkId: {
            type: String,
            required: true
        },
        msisdn: {
            type: String,
            required: true
        },
        macAddress: {
            type: String,
            required: true
        }
    },
    data() {
      const auth = authStore()

      return {
        auth,
        refreshBool: true,
        parts: [
          {
            name: "sessions",
            icon: "",
            displayName: "Sessions",
            permissions: ["*"],
          },
          {
            name: "summary",
            icon: "",
            displayName: "Summary",
            permissions: ["*"],
          },
        ],

        network: {
          ssid: '',
          domain: ''
        },

        user: {
          msisdn: '',
          currentlyCapped: ''
        },

        device: {
          macAddress: '',
          created: ''
        }
      }
    },
    mounted() {
      this.syncData();
    },
    methods: {
      syncData() {
        this.getUser();
        this.getDevice();
        this.getNetwork();
        this.getSessions();
        this.getSummary();
      },
      getSessions: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/sessions/' + this.msisdn + '/' + this.macAddress).then(
          (response) => {
            this.sessions = response.data;
            this.refreshTheBool()
          },
          (error) => {
            console.error(error);
          }
        )
      },
      getSummary: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/usage/monthly/' + this.msisdn + '/' + this.macAddress).then(
          (response) => {
            this.summary = response.data;
            this.refreshTheBool()
          },
          (error) => {
            console.error(error);
          }
        )
      },
      getNetwork: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/networks/organization/' + this.auth.getOrg() + '/network/' + this.networkId).then(
          (response) => {
            this.network = response.data;
          },
          (error) => {
            console.error(error);
          }
        )
      },
      getUser: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/users/network/' + this.networkId + '/msisdn/' + this.msisdn).then(
          (response) => {
            this.user = response.data;
          },
          (error) => {
            console.error(error);
          }
        )
      },
      getDevice: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/devices/' + this.msisdn + '/' + this.macAddress).then(
          (response) => {
            this.device = response.data;
          },
          (error) => {
            console.error(error);
          }
        )
      },
      deleteDevice: function() {
        const swal = require('sweetalert2');
        swal.fire({
          title: "Delete Device",
          text: "Are you sure?",
          icon: "warning",
          color: "#E0E0E0",
          iconColor: "#D71A20",
          background: "#212529",
          confirmButtonColor: "#D71A20",
          showCancelButton: true,
          confirmButtonText: "Delete",
          cancelButtonText: `Cancel`
        }).then((result) => {
                    
          if (result.isConfirmed) {
            this.$http.delete(this.$config.pawsAPI + '/v3/wifi/devices/' + this.msisdn + '/' + this.macAddress).then(
              (response) => {
                swal.fire({
                  title: "Success",
                  text: "Successfully Deleted Device.",
                  icon: "success",
                  color: "#E0E0E0",
                  iconColor: "##3dbe59",
                  background: "#212529",
                  confirmButtonColor: "#0D6EFD",
                }).then((a) => {
                  this.$router.push('/network/' + this.networkId + '/user/' + this.msisdn);   
                });
              },
              (error) => {
                console.error(error);
              }
            )
          }

        });
      },
      refreshTheBool() {
        this.refreshBool = false;
        this.refreshBool = true;
      }
    }
}
</script>