<template>
  <div>
    <div class="card basic-card mt-4">
      <div class="card-body">
        <h1 class="card-title highlight">Realm</h1>
        <div class="card-text">
          <p>
            Also known as a <span class="highlight">GSM</span> Network.
          </p>
          <p>
            The Global System for Mobile Communications (<span class="highlight">GSM</span>) is a standard developed by the European Telecommunications Standards Institute (<span class="highlight">ETSI</span>) to describe the protocols for second-generation (<span class="highlight">2G</span>) digital cellular networks used by mobile devices such as mobile phones and tablets.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'GsmNetwork',
    components: {
      
    },
    setup() {
      
    },
    data() {
      return {
        
      }
    },
    mounted() {
        
            
    },
    methods: {
    },
}
</script>

<style>

</style>