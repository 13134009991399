<template>
    <div class="wallpaper">

        <div class="container">
            <form class="card basic-card login-card" @submit="handleSubmit">
                <div class="card-body">
                    <h3 class="card-title highlight">Log in</h3>
                    
                    <div class="input-group mt-4 mb-3">
                        <span class="input-group-text"><i class="fa-solid fa-envelope"></i></span>
                        <input class="form-control login-input" type="text" placeholder="Username" autofocus v-model="user.username">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text"><i class="fa-solid fa-lock"></i></span>
                        <input class="form-control login-input" type="password" placeholder="Password" v-model="user.password"/>
                    </div>
                    
                    <div class="mt-4">
                        <a class="login-forget-link" href="#">Forgot your password?</a>
                    </div>
                </div>

                <button class="btn login-button" type="submit">
                    <span class="state">Log in</span>
                </button>
                <!-- <div class="spinner-border text-primary highlight col-1" role="status">
                </div> -->

            </form>
        </div>
        
    </div>
</template>

<script>
import { authStore } from '@/stores/AuthStore.ts';

export default {
    name: 'Login',
    components: {
        
    },
    data() {
        const auth = authStore();
        return {
            auth,
            working: false,
            user: {
                username: '',
                password: ''
            },
            users: [
                {
                    role: "admin",
                    username: "admin",
                    password: "123",
                },
                {
                    role: "client",
                    username: "cadmin",
                    password: "123",
                }
            ]
        }
    },
    mounted() {
        
    },
    methods: {
        toOrg() {
            if (this.auth.getAdmin() == "true") {
                this.$router.push('orgs') 
            } else {
                this.$router.push('dashboard') 
            }
        },
        authenticate() {
            return this.$http.post(this.$config.pawsAPI + '/v3/login', this.user).then(
                (response) => {
                    this.auth.setToken(response.data.token);
                    this.auth.setAdmin(response.data.admin);
                    this.auth.setOrgName(response.data.organizationName);
                    this.auth.setOrg(response.data.organizationId);

                    return response.data;
                },
                (error) => {
                    console.error(error);
                    throw error;
                }
            )
        },
        handleSubmit(e) {
            e.preventDefault();
            if (this.working) return;

            this.working = true;
            const $this = e.target,
            $state = $this.querySelector('button > .state');
            $this.classList.add('loading');
            $state.innerHTML = 'Authenticating';

            setTimeout(() => {
                this.authenticate().then(
                    (response) => {
                        $this.classList.add('ok');
                        $state.innerHTML = 'Welcome';
                        setTimeout(() => {
                            $state.innerHTML = 'Log in';
                            $this.classList.remove('ok', 'loading');
                            this.working = false;
                            this.toOrg();
                        }, 1000);
                    },
                    (error) => {
                        $this.classList.add('not-ok');
                        $state.innerHTML = 'Invalid User';
                        setTimeout(() => {
                            $state.innerHTML = 'Log in';
                            $this.classList.remove('not-ok', 'loading');
                            this.working = false;
                        }, 1000);
                    }
                )
            }, 1000);
            
        },
    },
}
</script>

<style scoped>

.container {
    position: absolute; /* Set absolute positioning */
    right: calc(50% - 650px); /* Position the left edge at 50% of the viewport width minus half of the div width */
    top: calc(50% + 130px); /* Center the div vertically */
    max-width: 500px;
    margin: 0 auto;
    padding: 50px 0;
}

.login-card {
    border-width: 4px;
    border-radius: 0px;
    border-color: var(--input-border-color);
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 330px;
    
    padding: 10px 20px 20px 20px;
    position: relative;
    padding-bottom: 80px;
    box-shadow: 2px 2px 20px #00000075;
}


.login-input {
    border-width: 4px 4px 4px 0px;
    border-radius: 0px 0.375rem 0.375rem 0px;
    color: var(--input-placeholder-color);
    border-color: var(--input-border-color);
    background-color: var(--input-background-color);

    transition: all .6s ease-in-out;
}

.login-input:hover,
.login-input:focus {
    color: var(--highlighted-text);
    border-color: var(--input-border-color);
    background-color: var(--input-background-color);
}

.input-group-text {
    border-width: 4px 0px 4px 4px;
    color: var(--text-header-color);
    background: var(--input-border-color);
    border-color: var(--input-border-color);
    transition: all .6s ease-in-out;
}

.login-forget-link {
    color: var(--input-placeholder-color);
    text-decoration: none;
    transition: all 0.6s ease-in-out; 
}

.login-button, .login-button:focus {
    z-index: 10;
    position: absolute;
    bottom:0;
    left:0;
    border-radius: 0;
    height: 3em;
    color: #FFFFFF;
    width: 100%;
    border-color: var(--button-color);
    background-color: var(--button-color);
    transition: all 0.6s ease-in-out;
}

.login-button:hover {
    color: #FFFFFF;
    border-color: var(--highlighted-text);
    background-color: var(--highlighted-text);
}

.login-card.loading .login-button {
    width: 100%;
    height: 100%;
    background-color: var(--button-color);
    transition: all 0.7s ease-in-out; 
}

.login-card.ok .login-button  {
    border-color: var(--success-color);
    background-color: var(--success-color);
}

.login-card.not-ok .login-button  {
    border-color: var(--error-color);
    background-color: var(--error-color);
}

.login-forget-link:hover {
    color: var(--highlighted-text);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px var(--highlighted-text) inset !important;
    -webkit-text-fill-color: #FFF !important;
}

</style>