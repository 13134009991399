import * as Vue from 'vue' // in Vue 3

import { createPinia } from 'pinia'
import { authStore } from './stores/AuthStore.ts';

import App from './App.vue'
import router from './config/router'
import VueConfig from 'vue-config'
import VueParticles from 'vue-particles';
import vSelect from 'vue-select'
import VueAxios from 'vue-axios'
import VTooltip from 'v-tooltip';
import axios from 'axios'

import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import '@fortawesome/fontawesome-free/js/all'
import './config/main.css' // Import global CSS file

import globalMethods from './config/globalMethods';
import pawsConfig from './config/pawsConfig'

const app = Vue.createApp(App)
const pinia = createPinia();

app.use(pinia)
app.use(globalMethods)
app.use(VueConfig, pawsConfig)

app.use(VTooltip);

app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.use(VueParticles);
app.component('v-select', vSelect)

app.use(router);

const auth = authStore()

axios.interceptors.request.use(
    (config) => {
        if (auth.getToken()) {
            config.headers['Authorization'] = `Bearer ${auth.getToken()}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
});

axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        auth.setToken('')
        auth.setAdmin(false)
        auth.setOrgName('')
        auth.setOrg(0)
        router.push("/login")
      }
      return Promise.reject(error);
    }
  );

router.beforeEach((to, from, next) => {
    if (to.path !== '/login' && !auth.getToken()) {
        next('/login');
    } else {
        next();
    }
});

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});

app.mount('#app')
