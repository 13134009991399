<template>
    <div class="title text-light mt-3">P<img class="adeptLogo" src="@/images/adept-icon-alt.png"/><div class="title-ws">WS</div> <img class="pawLogo" src="@/images/paw-icon.png"/></div>
</template>

<script>
export default {
    name: 'Splash'
}
</script>

<style scoped>
.adeptLogo {
    display: inline-flex;
    height: 80px;
    margin-top: -18px;
}

.pawLogo {
    display: inline-flex;
    height: 85px;
    margin-top: -15px;
}

.title {
    cursor: default;
    font-family: 'NunitoBold', sans-serif;
    font-size: 100px;
    position: absolute; /* Set absolute positioning */
    left: calc(50% - 550px); /* Position the left edge at 50% of the viewport width minus half of the div width */
    top: calc(50% - 100px); /* Center the div vertically */
}

.title-ws {
    display: inline-flex;
    letter-spacing: 8px;
}
</style>