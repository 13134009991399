<template>
  <div>
    <div class="card basic-card">
      <div class="card-body">
        <h1 class="card-title highlight">{{ user.msisdn }}</h1>
        <p class="card-text">
          <span class="highlight">Network SSID:</span> {{ network.ssid }}
        </p>
        <p class="card-text">
          <span class="highlight">Network Domain:</span> {{ network.domain }}
        </p>
        <p class="card-text">
          <span class="highlight">Currently Capped:</span> <span :class="user.currentlyCapped ? 'highred' : 'highgreen'"> {{ user.currentlyCapped ? 'Yes' : 'No' }} </span>
        </p>
        <p class="card-text">
          <span class="highlight">Created:</span> {{ user.created.split(" ")[0] }}
        </p>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2">
      <div class="col-md-4 mt-4">
        <div class="card basic-card">
          <div class="card-body">
            <button class="btnAdd" data-bs-toggle="modal" data-bs-target="#deviceModal" @click="newDevice"><i class="addIco fa-regular fa-square-plus"></i></button>
            <h3 class="card-title highlight">Devices</h3>
            <hr/>
            <div class="card-list mt-4">
              <DeviceCard v-for="(device, index) in devices" :key="index + device.id"
                :networkId="networkId"
                :msisdn="msisdn"
                :macAddress="device.macAddress"
                :description="device.description"
                :status="device.status"
                :created="device.created"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 mt-4">
        <div class="card basic-card">
          <div class="card-body">
            <button class="btnAdd" data-bs-toggle="modal" data-bs-target="#allocationModal" @click="newDataAllocation"><i class="addIco fa-regular fa-square-plus"></i></button>
            <h3 class="card-title highlight">Data Allocation</h3>
            <hr/>
            <div class="card-list mt-4">
              <div class="table-responsive table-bordered">
                <table class="paws-table table">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Used</th>
                      <th>Remaining</th>
                      <th>From</th>
                      <th>To</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(dataA, index) in dataAllo"
                      :key="index + '- DataAllocation'"
                    >
                      <td>{{ dataA.description }}</td>
                      <td :class="dataA.status == 'ACTIVE' ? 'highgreen bold' : 'highred bold'" >{{ dataA.status }}</td>
                      <td>{{ $sizeBytesToMb(dataA.bytesConsumed) }} <span class="highlight">MB</span></td>
                      <td>{{ $sizeBytesToMb(dataA.bytesRemaining) }} <span class="highlight">MB</span></td>
                      <td>{{ dataA.validFrom.split(" ")[0] }}</td>
                      <td>{{ dataA.validUntil.split(" ")[0] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Device Modal -->
    <div class="modal fade" id="deviceModal" tabindex="-1" aria-labelledby="deviceModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <DeviceModal
            :msisdn="msisdn"
            :device="selectedDevice"
            @sync="syncData()"
          />
        </div>
      </div>
    </div>

    <!-- Data Allocation Modal -->
    <div class="modal fade" id="allocationModal" tabindex="-1" aria-labelledby="allocationModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <DataAllocationModal
            :dataAllocation="selectedDataAllocation"
            @sync="syncData()"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { authStore } from '@/stores/AuthStore.ts';
import DeviceCard from '@/components/objects/DeviceCard'
import DeviceModal from '@/components/modal/DeviceModal'
import DataAllocationModal from '@/components/modal/DataAllocationModal'
import NetworkCard from '@/components/objects/NetworkCard.vue'

export default {
    name: 'User',
    components: {
      DeviceCard,
      DeviceModal,
      DataAllocationModal,
    },
    props: {
        networkId: {
            type: String,
            required: true
        },
        msisdn: {
            type: String,
            required: true
        },
    },
    setup() {
      
    },
    data() {
      const auth = authStore()

      return {
        auth,
        devices: {},
        dataAllo: {},

        user: {
          msisdn: '',
          created: '',
          currentlyCapped: ''
        },

        network: {
          ssid: '',
          domain: ''
        },

        selectedDevice: {
          macAddress: "",
          description: "",
          status: null,
          created: "",
        },

        selectedDataAllocation: {
          msisdn: this.msisdn,
          size: "",
          uom: "MB",
          validFrom: "",
          validTo: "",
        }
      }
    },
    mounted() {
      this.syncData();
    },
    methods: {
      syncData() {
        this.getUser();
        this.getNetwork();
        this.getDevices();
        this.getDataAllocations();
      },
      getUser: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/users/network/' + this.networkId + '/msisdn/' + this.msisdn).then(
          (response) => {
            this.user = response.data;
          },
          (error) => {
            console.error(error);
          }
        )
      },
      getNetwork: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/networks/organization/' + this.auth.getOrg() + '/network/' + this.networkId).then(
          (response) => {
            this.network = response.data;
          },
          (error) => {
            console.error(error);
          }
        )
      },
      getDevices: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/devices/' + this.msisdn).then(
          (response) => {
            this.devices = response.data;
          },
          (error) => {
            console.error(error);
          }
        )
      },
      getDataAllocations: function() {
        this.$http.get(this.$config.pawsAPI + '/v3/wifi/data/' + this.msisdn).then(
          (response) => {
            this.dataAllo = response.data;
          },
          (error) => {
            console.error(error);
          }
        )
      },
      newDevice() {
        this.selectedDevice = {
          macAddress: "",
          description: "",
          status: null,
          created: "",
        }
      },
      selectDevice(device) {
        this.selectedDevice = device;
      },
      newDataAllocation() {
        this.selectedDataAllocation = {
          msisdn: this.msisdn,
          size: "",
          uom: "MB",
          validFrom: "",
          validTo: "",
        }
      },
    },
}
</script>