export default {
    install(app) {
        app.config.globalProperties.$sizeBytesToMb = function (value) {
            if (value && value > 0) {
                return (value / 1024 / 1024).toFixed(2);
            } else {
                return 0;
            }
        };
        app.config.globalProperties.$sizeMbToBytes = function (value) {
            if (value && value > 0) {
              return (value * 1024 * 1024);
            } else {
              return 0;
            }
        };
    }
};
  