<template>
    <div class="wallpaper">
        <div class="container">
            <div class="trCorner"></div>
            <button class="btnOrg" data-bs-toggle="modal" data-bs-target="#orgModal"><i class="addIco fa-regular fa-square-plus"></i></button>
        
            <Swiper
                :effect="'cards'"
                :grabCursor="false"
                :centeredSlides="true"
                :slidesPerView="'auto'"
                :coverflowEffect="{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                }"
                :pagination="false"
                :navigation="true"
                :modules="modules"
                :allowTouchMove="true"
                class="mySwiper"
            >
                <SwiperSlide v-for="organisation in organisations" :key="'slide-' + organisation">
                    <div class="card basic-card org-card" @click="enterOrganisation(organisation.id, organisation.name)">
                        <img class="card-image-top" :src="`../organisations/${organisationImage(organisation.name)}.png`" alt="Organisation Image" @error="handleImageError">
                        <div class="card-body">
                            <h5 class="card-title">{{organisation.name}}</h5>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
        
        <!-- Org Modal -->
        <div class="modal fade" id="orgModal" tabindex="-1" aria-labelledby="orgModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <OrgModal
                    @sync="loadOrganisations()"
                />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import OrgModal from '../components/modal/OrgModal.vue';

import { Swiper, SwiperSlide } from 'swiper/vue'
import { EffectCoverflow, Navigation, EffectCards } from 'swiper/modules';
import { authStore } from '@/stores/AuthStore.ts';

import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';

import 'swiper/css';

export default {
    name: 'Organisations',
    components: {
        OrgModal,
        Swiper,
        SwiperSlide
    },
    setup() {
      return {
        modules: [EffectCoverflow, Navigation, EffectCards],
      };
    },
    data() {
        const auth = authStore();
        return {
            auth,
            organisations: [],
        }
    },
    mounted() {
        this.loadOrganisations();
            
    },
    methods: {
        loadOrganisations() {
            this.$http.get(this.$config.pawsAPI + '/v3/wifi/organizations').then(
                (response) => {
                    this.organisations = response.data;
                },
                (error) => {
                    console.error(error);
                }
            )
        },
        enterOrganisation(org, name) {
            this.auth.setOrg(org)
            this.auth.setOrgName(name)
            this.$router.push('/dashboard') 
        },
        organisationImage(name) {
            return name.toLowerCase().replace(/\s/g, "");
        },
        handleImageError(event) {
            event.target.src = '../organisations/default.png';
        },
        toggleShrink(element) {
            element.classList.toggle('shrink');
        }
    },
}
</script>

<style scoped>

.container {
    position: absolute; /* Set absolute positioning */
    right: calc(50% - 565px); /* Position the left edge at 50% of the viewport width minus half of the div width */
    top: calc(50% + 90px); /* Center the div vertically */
    max-width: 500px;
    margin: 0 auto;
    padding: 50px 0;
}

.org-card {
    border-radius: 0% 0% 10% 10%;
    border-width: 4px;
    width: 18em; 
    cursor: pointer;
    transition: all 1s ease; /* Apply transition to all properties */
}

.org-card:hover {
    border-color: var(--highlighted-text);
}

.org-card.active {
    transition: all .6s ease-in-out;
    transform: scale(0.50);
}

.trCorner {
    position: fixed;
    right: 0;
    top: 0;

    height: 8em;
    width: 8em;

    background-color: #212529;
    border-bottom-left-radius: 100%;
}

.btnOrg {
    background-color: transparent;
    position: fixed;
    font-size: x-large;
    color: var(--white-text) !important;
    top: 1em;
    right: 1em;
    border: none !important;
    transition: all .4s ease-in-out;
}

.btnOrg:hover {
    color: var(--highlighted-text) !important;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 330px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
</style>