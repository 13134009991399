<template>
  <div class="parent">
    <ParticlesBG :numOfParts="75"/>
    <transition name="fade" mode="out-in">
      <div v-if="auth.getOrg() > 0 && noHeaderRoutes($route.path)" key="app">
        <Header/>
        <div class="app-content">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component"/>
            </transition>
          </router-view>
        </div>
      </div>
      <div v-else key="pre-app">
        <Splash/>
        <div class="content">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component"/>
            </transition>
          </router-view>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Splash from './components/objects/Splash'
import Header from './components/objects/Header'
import ParticlesBG from './components/effects/ParticlesBG.vue';
import { authStore } from '@/stores/AuthStore.ts';

export default {
  name: 'App',
  components: {
    Splash,
    Header,
    ParticlesBG,
  },
  data() {
    const auth = authStore()
    return {
      auth,
    }
  },
  methods: {
    noHeaderRoutes(path) {
      return path !== '/login' && path !== '/orgs';
    },
    sizeBytesToMb: function (value) {
      if (value && value > 0) {
        return (value / 1024 / 1024).toFixed(2);
      } else {
        return 0;
      }
    },
  }
}
</script>

<style>
:root {
    --theme-color: #0D6EFD; /* Define the color variable */
    --body-background-color: #0F1214;
    --body-text-color: #E0E0E0;
    --text-header-color: #AAABAC;
    --body-card-color: #212529;
    --body-card-dark: #1d2124;
    --header-highlight: #56b3ff;
    --highlighted-text: #2B9AF3;
    --white-text: #eeeeee;
    --button-color: rgb(22, 137, 251);
    --delete-color: #bb181e;
    --input-background-color: #0F1214;
    --input-border-color: #1b1c1d;
    --input-text-color: #E0E0E0;
    --input-placeholder-color: #AAABAC;

    --success-color: #3dbe59;
    --error-color: #D71A20;
}

@font-face {
    font-family: 'Nunito';
    src: url('fonts/Nunito-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'NunitoBold';
    src: url('fonts/Nunito-Bold.ttf') format('truetype');
}

.parent {
  position: relative;
  min-height: 100vh;
  height: 100%;
}

#app {
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* background-color: #F5F7FA; */
}

#app,
html,
body {
  background-color: var(--body-background-color);
  color: var(--body-text-color);
  font-size: 15px;
}

.nunBold {
  font-family: 'NunitoBold', 'Nunito', sans-serif;
}

.content {
  width: 75%;
  min-width: 500px;
  margin: 0 auto;
  position: relative;
}

.app-content {
  width: 75%;
  min-width: 500px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 5em;
  padding-top: 120px;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}
</style>
