<template>
    <div class="navhead">
        <nav class="navbar bg-dark fixed-top">
            <div class="container-fluid">
                <a class="navbar-brand nunBold indented-1p curse-normal"><h1 class="title">P<img class="adeptLogo" src="@/images/adept-icon-blu.png"/><div class="titleSpacing">WS</div></h1></a>
                <div class="righted">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <RouterLink class="nav-link" to="/dashboard">Dashboard</RouterLink>
                        </li>
                        <div class="vr"/>   
                    </ul>
                    <button class="navbar-toggler btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                        <span class="fa-regular fa-user"></span>
                    </button>
                </div>
            </div>
        </nav>
    </div>
    <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">    
        <div class="offcanvas-header d-flex justify-content-between align-items-center">
            <h3 class="offcanvas-title" id="offcanvasDarkNavbarLabel">{{network}}</h3>
            <button type="button" class="btn icon-medium" data-bs-dismiss="offcanvas" aria-label="Close">
                <span class="fa-solid fa-arrow-right-from-bracket"></span>
            </button>
        </div>
        <div class="offcanvas-body d-flex flex-column">
            <ul class="navbar-nav flex-column mb-auto">
                <hr/>
                <li class="nav-item mt-3">
                    <RouterLink class="nav-link" :to="'/org/' + this.auth.getOrg()">
                    <!-- <i class="fa-solid fa-mug-hot"></i>  -->
                    {{ this.auth.getOrgName() }}</RouterLink>
                </li>
                <li class="nav-item mt-3">
                    <RouterLink class="nav-link" to="/dashboard">
                    <!-- <i class="fa-solid fa-mug-hot"></i>  -->
                    Dashboard</RouterLink>
                </li>
            </ul>
            <ul class="navbar-nav flex-column">
                <hr/>
                <li class="nav-item curse-point" v-if="this.auth.getAdmin() == 'true'" @click="this.$router.push('/orgs')">
                    <div class="nav-link">
                        Exit Org
                    </div>
                </li>
                <li class="nav-item curse-point" @click="logout()">
                    <div class="nav-link">
                        Logout
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { authStore } from '@/stores/AuthStore.ts';

export default {
    data() {
        const auth = authStore()

        return {
            auth,
            network: "",
        }
    },
    components: {

    },
    mounted() {
        // Listen to Bootstrap offcanvas events
        const offcanvas = document.getElementById('offcanvasDarkNavbar');
        offcanvas.addEventListener('show.bs.offcanvas', this.handleOffcanvasShow);
        offcanvas.addEventListener('hidden.bs.offcanvas', this.handleOffcanvasHidden);

        // Listen to click events on backdrop
        document.addEventListener('click', this.handleBackdropClick);

        // Listen to route changes
        this.$router.afterEach(this.handleRouteChange);
    },
    methods: {
        handleOffcanvasShow() {
            // Add the backdrop when offcanvas is shown
            const backdrop = document.createElement('div');
            backdrop.classList.add('offcanvas-backdrop', 'fade', 'show');
            document.body.appendChild(backdrop);
        },
        handleOffcanvasHidden(event) {
            // Check if the offcanvas is hidden because of navigation
            const isNavigationHidden = event.relatedTarget && event.relatedTarget.tagName === 'A';
            if (!isNavigationHidden) {
                // Remove all backdrop elements when offcanvas is hidden
                this.removeBackdrop();
            }
        },
        handleBackdropClick(event) {
            // Check if the clicked element is a backdrop
            if (event.target.classList.contains('offcanvas-backdrop')) {
                // Find and click the close button of the offcanvas
                const closeButton = document.querySelector('#offcanvasDarkNavbar .icon-medium');
                if (closeButton) {
                    closeButton.click();
                }
            }
        },
        removeBackdrop() {
            // Remove all backdrop elements
            const backdrops = document.querySelectorAll('.offcanvas-backdrop');
            backdrops.forEach(backdrop => backdrop.remove());
        },
        handleRouteChange(to, from) {
            this.removeBackdrop(); // Remove backdrop when navigating to the landing page
        },
        getNetwork() {
            return this.$http.get(this.$config.pawsAPI + '/v3/wifi/organizations/users', this.user).then(
                (response) => {
                    this.auth.setToken(response.data.token);

                    return response.data;
                },
                (error) => {
                    console.error(error);
                    throw error;
                }
            )
        },
        logout() {
            this.auth.setToken('');
            this.auth.setAdmin(false);
            this.auth.setOrgName('')
            this.auth.setOrg(0);
            this.$router.push('/login')
        }
    },
}
</script>

<style scoped>
.navhead {
    background-color: var(--body-header-color);
    position: fixed;
    z-index: 123;
    top: 0;
    left: 0;
    right: 0;
}

.titleSpacing {
    display: inline-flex;
    letter-spacing: 3px;
}

.title {
    padding-top: 5px;
    padding-left: 10px;
    color: var(--highlighted-text);
}

.righted {
    display: flex;
    align-items: right;
}

.navbar {
    box-shadow: 0px 5px 15px #000000;
}

.navbar-nav {
    flex-direction: row; 
}

.navbar-toggler {
    border: none;
}

button:focus,
button:active {
    box-shadow: none !important; /* Additional rule to ensure outline removal */
}

button {
    border: none;
}

.navbar-nav .nav-item {
    display: inline-block;
}

.nav-item, .navbar-toggler {
    margin-left: 1em;
    margin-right: 1em;
}

.adeptLogo {
    display: inline-flex;
    max-height: 30px;
    margin-top: -7px;
}

.pawLogo {
    display: inline-flex;
    max-height: 30px;
    margin-top: -5px;
}

.offcanvas {
    max-width: 20em;
}

.offcanvas-header {
    padding: 1.5em 1.5em 0em 1.5em;
}

hr {
    height: 4px;
    opacity: 0.7;
    color: var(--text-header-color);
}

.vr {
    width: 2px;
    opacity: 0.7;
    margin-left: 1.5em;
    margin-right: 0.5em;
    color: var(--text-header-color);
}

.nav-link, button {
    color: var(--text-header-color)
}

.nav-link:hover, button:hover, .offcanvas-title {
    color: var(--header-highlight)
}

a.router-link-active {
    color: var(--header-highlight);
    border-bottom: 1px solid var(--header-highlight);
}

.flex-column .nav-item {
    text-align: left;
}

.flex-column .nav-item .nav-link {
    display: inline-block;
}

.flex-column .nav-item a.router-link-active {
    border-bottom: none;
}

.dropdown {
    color: var(--text-header-color)
}

.dropdown-toggle.nav-link {
    color: inherit; /* Specify the color of the text */
}

.dropdown-toggle.nav-link:focus {
    color: var(--header-highlight);
}

.dropdown-toggle.nav-link:hover {
    color: var(--header-highlight);
}
</style>
