<template>
    <div>
        <div class="card mb-3 curse-point" :class="deleting ? 'delete-card' : ' router-card'" @click="openModal">
            <div class="card-header"><h5>{{ routerHost }}</h5></div>
                <div class="card-body">
                <div class="card-text">  
                    <div class="highlight">Router ID:</div>
                    <div class="rowValue">{{ routerId }}</div>
                </div>
                <div class="card-text">  
                    <div class="highlight">Encrypted:</div>
                    <div class="rowValue">{{ encrypted ? "Yes" : "No" }}</div>
                </div>
                <div class="card-text">
                    <div class="highlight">Created:</div>
                    <div class="rowValue">{{ created.split(" ")[0] }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'RouterCard',
    components: {
        
    },
    props: {
        id: {
            type: Number,
            required: true
        },
        networkId: {
            type: Number,
            required: true
        },
        routerId: {
            type: String,
            required: true
        },
        routerHost: {
            type: String,
            required: true
        },
        username: {
            type: String,
            required: true
        },
        password: {
            type: String,
            required: true
        },
        encrypted: {
            type: Boolean,
            required: true
        },
        created: {
            type: String,
            required: true
        },
        deleting: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        openModal() {
            this.$emit('openRouterModal', 
                { 
                    id: this.id,
                    networkId: this.networkId,
                    routerId: this.routerId,
                    routerHost: this.routerHost,
                    username: this.username,
                    password: this.password,
                    encrypted: this.encrypted,
                    created: this.created 
                }
            );
        },
    }
};
</script>

<style scoped>
.router-card {
    border-width: 3px;
    border-radius: 0px;
    border-color: var(--input-border-color);
    
    color: var(--body-text-color);
    background-color: var(--body-card-color);
    box-shadow: 5px 5px 2px #00000075; /* Define custom shadow */

    transition: all 0.6s ease-in-out; 
}

.router-card:hover {
    border-color: var(--highlighted-text);
}

.delete-card {
    border-width: 3px;
    border-radius: 0px;
    border-color: var(--input-border-color);
    
    color: var(--body-text-color);
    background-color: var(--body-card-color);
    box-shadow: 5px 5px 2px #00000075; /* Define custom shadow */

    transition: all 0.6s ease-in-out; 
}

.delete-card:hover {
    border-color: var(--error-color);
}

.card-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rowValue {
    margin-left: 0.5em;
}

</style>